import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue, CustomFieldTypeName } from '@air/api/types';
import { memo, useCallback, useState } from 'react';

import {
  CustomFieldSelect,
  CustomFieldSelectProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldSelect';
import { chipsToCustomField, chipToCustomField } from '~/components/CustomFields/CustomFieldInput/utils';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetPrivateCustomFieldOptions } from '~/swr-hooks/customFields/useGetPrivateCustomFieldOptions';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateBoardCustomFieldSelectProps
  extends Omit<CustomFieldSelectProps, 'onSelectionChange' | 'cfOptions'> {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  boardId: string;
}

export const PrivateBoardCustomFieldSelect = memo(
  ({ field, trackingLocation, canEditCustomFields, boardId, ...rest }: PrivateBoardCustomFieldSelectProps) => {
    const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;
    const { data: cfOptions } = useGetPrivateCustomFieldOptions({
      customFieldId: field.id,
      enabled: !shouldLoadOptions,
    });

    const onSelectionChange: CustomFieldSelectProps['onSelectionChange'] = useCallback(
      async ({ addedChips, removedChips, chips }) => {
        if (!boardId || !workspaceId) return;

        if (field.type.title === CustomFieldTypeName.singleSelect) {
          const value = chips.length && !!cfOptions ? chipToCustomField(chips[0], cfOptions.data) : null;

          await updateItemsCustomFieldValue({
            boardIds: [boardId],
            baseCustomField: field,
            nextValue: value,
            trackingLocation,
            workspaceId,
          });
        } else {
          const selectedFields = chips.length && !!cfOptions ? chipsToCustomField(chips, cfOptions.data) : [];

          await updateItemsCustomFieldValue({
            boardIds: [boardId],
            baseCustomField: field,
            nextValues: selectedFields,
            multiValuesToAdd: addedChips && cfOptions ? chipsToCustomField(addedChips, cfOptions.data) : undefined,
            multiValueIdsToRemove: removedChips?.map((chip) => chip.value),
            trackingLocation,
            workspaceId,
          });
        }
      },
      [boardId, cfOptions, field, trackingLocation, updateItemsCustomFieldValue, workspaceId],
    );

    return (
      <CustomFieldSelect
        {...rest}
        field={field}
        onSelectionChange={onSelectionChange}
        cfOptions={cfOptions}
        onOptionsOpen={() => setShouldLoadOptions(true)}
        isLoading={shouldLoadOptions && !cfOptions}
        disabled={!canEditCustomFields}
      />
    );
  },
);

PrivateBoardCustomFieldSelect.displayName = 'PrivateBoardCustomFieldSelect';
