import { Board, Board as BoardInterface } from '@air/api/types';
import { memo, ReactNode } from 'react';

import { BoardGalleryCard, BoardGalleryCardProps } from '~/components/Gallery/BoardGalleryCard/BoardGalleryCard';
import { DraggingOverlay } from '~/components/Gallery/DraggingOverlay';
import { DNDParentBoard } from '~/components/Shared/Drag/DNDParentBoard';
import DragType from '~/components/Shared/Drag/dragTypes';
import { DragDroppableBoardShellProps, DraggableBoardCard } from '~/components/Shared/DraggableBoardContainer';
import { DropHighlight } from '~/components/Shared/DropHighlight';

interface ReactDNDBoardCardContainerProps {
  board: BoardInterface;
  canDropAssets: boolean;
  children: ReactNode;
}

const ReactDNDBoardCardContainer = memo(({ board, canDropAssets, children }: ReactDNDBoardCardContainerProps) =>
  canDropAssets ? (
    <DNDParentBoard dropLocation="board" board={board}>
      <DropHighlight />
      {children}
    </DNDParentBoard>
  ) : (
    // requires fragement wrapper because TS needs JSX.Element
    <>{children}</>
  ),
);

ReactDNDBoardCardContainer.displayName = 'ReactDNDBoardCardContainer';

export interface DNDBoardGalleryCardProps
  extends BoardGalleryCardProps<Board>,
    Pick<DragDroppableBoardShellProps, 'onDragStart'> {
  canDropAssets?: boolean;
  enableDrag?: boolean;
  index?: number;
}

export const DNDBoardGalleryCard = memo(
  ({ board, canDropAssets = false, enableDrag = true, index, onDragStart, ...rest }: DNDBoardGalleryCardProps) => {
    return (
      <DraggableBoardCard board={board} enabled={enableDrag} index={index} onDragStart={onDragStart}>
        <ReactDNDBoardCardContainer canDropAssets={canDropAssets} board={board}>
          <BoardGalleryCard board={board} {...rest} />
          <DraggingOverlay itemId={board.id} type={DragType.boardCard} />
        </ReactDNDBoardCardContainer>
      </DraggableBoardCard>
    );
  },
);

DNDBoardGalleryCard.displayName = 'DNDBoardGalleryCard';
