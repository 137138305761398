import { Board } from '@air/api/types';
import React, { memo, PropsWithChildren, useMemo } from 'react';

import {
  BoardDragItem,
  DNDMovableToBoardItem,
} from '~/components/Shared/Drag/DNDMovableToBoardItem/DNDMovableToBoardItem';
import { DNDParentBoard } from '~/components/Shared/Drag/DNDParentBoard';
import DragType from '~/components/Shared/Drag/dragTypes';
import { DropHighlight } from '~/components/Shared/DropHighlight';
import { BoardTableRowProps } from '~/components/TableView/BoardTableRow';

interface PrivateDraggableBoardTableRowContainerProps {
  board: BoardTableRowProps<Board>['data'];
}

export const PrivateDraggableBoardTableRowContainer = memo(
  ({ children, board }: PropsWithChildren<PrivateDraggableBoardTableRowContainerProps>) => {
    const draggableItem: BoardDragItem = useMemo(() => ({ board, type: DragType.boardCard }), [board]);

    return (
      <DNDParentBoard dropLocation="board" board={board}>
        <DropHighlight style={{ right: -16, zIndex: -1 }} />
        <DNDMovableToBoardItem id={board.id} item={draggableItem}>
          {children}
        </DNDMovableToBoardItem>
      </DNDParentBoard>
    );
  },
);

PrivateDraggableBoardTableRowContainer.displayName = 'PrivateDraggableBoardTableRowContainer';
