import { FiltersTrackingLocation } from '@air/analytics';

import { SearchResultsNumber } from '~/components/SearchResultsNumber';
import { usePrivateSearchTotals } from '~/swr-hooks/search/usePrivateSearchTotals';

export interface PrivateSearchResultsNumberProps {
  canShowBoards: boolean;
  location?: string;
  trackLocation: FiltersTrackingLocation;
}

export const PrivateSearchResultsNumber = ({
  canShowBoards,
  location,
  trackLocation,
}: PrivateSearchResultsNumberProps) => {
  const { total } = usePrivateSearchTotals({ showBoards: canShowBoards, trackLocation });

  return <SearchResultsNumber total={total} location={location} />;
};
