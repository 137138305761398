import { Board as BoardInterface } from '@air/api/types';
import { isEqual } from 'lodash';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { GalleryViewRender } from '~/components/Gallery/types';
import { useLibraryCustomFields } from '~/components/LibraryBeta/hooks/queries/useLibraryCustomFields';
import { DNDBoardGalleryCard, DNDBoardGalleryCardProps } from '~/components/PrivateGallery/DNDBoardGalleryCard';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useOpenBoardInNewTab } from '~/hooks/useOpenBoardInNewTab';
import { usePrivateSelectionMenuOptions } from '~/hooks/usePrivateSelectionMenuOptions';
import { useURLLibraryIdSelector } from '~/hooks/useURLLibraryIdSelector';
import { centralizedBoardLibraryIdSelector } from '~/store/centralizedBoard/selectors';
import { canSeeBoardCustomFields } from '~/utils/permissions/boardPermissions';
import { isMobileAgent } from '~/utils/PlatformHelpers';

import { useGetPrivateBoardMenuOptionsCallback } from './useGetPrivateBoardMenuOptionsCallback';

export interface PrivateGalleryBoardProps
  extends GalleryViewRender<BoardInterface>,
    Pick<DNDBoardGalleryCardProps, 'ancestors'> {}

export const PrivateGalleryBoard = memo(
  ({ data: board, box, index, ancestors }: PrivateGalleryBoardProps) => {
    const { boardPermissions } = useBoardPermissions({
      boardId: board.id,
    });
    const { getSelectionMenuOptions } = usePrivateSelectionMenuOptions();
    const { getBoardMenuOptionsCallback } = useGetPrivateBoardMenuOptionsCallback();
    const _getItemMenuOptions = getBoardMenuOptionsCallback({ item: board });
    const { goToBoardPage } = useGoToBoardPage();
    const { openBoardInNewTab } = useOpenBoardInNewTab();
    const urlLibraryId = useURLLibraryIdSelector();
    const libraryId = useSelector(centralizedBoardLibraryIdSelector);
    const { data: libraryCustomField } = useLibraryCustomFields({
      libraryId: libraryId || urlLibraryId,
    });

    const onBoardClick = useCallback(() => goToBoardPage({ board, trackLocation: 'card' }), [board, goToBoardPage]);

    const onBoardCmdClick = useCallback(
      () => openBoardInNewTab({ board, trackLocation: 'card' }),
      [board, openBoardInNewTab],
    );

    return (
      <DNDBoardGalleryCard
        cardWidth={box.width}
        board={board}
        enableDrag={!isMobileAgent}
        index={index}
        isSelectable={true}
        libraryCustomFields={libraryCustomField?.data.map((customField) => ({
          ...customField,
          visible: !!customField.visible,
        }))}
        key={board.id}
        onClick={onBoardClick}
        onCmdClickTitle={onBoardCmdClick}
        getItemMenuOptions={_getItemMenuOptions}
        getSelectionMenuOptions={getSelectionMenuOptions}
        ancestors={ancestors}
        canDropAssets={true}
        canViewCustomFields={canSeeBoardCustomFields(boardPermissions)}
      />
    );
  },
  // `container` comes from `react-virtualized` and is a different object every time but may have the same values so we need to deep equal check the props
  isEqual,
);

PrivateGalleryBoard.displayName = 'PrivateGalleryBoard';
