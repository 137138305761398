import { Board } from '@air/api/types';
import { EllipsisVertical, Pencil } from '@air/next-icons';
import { DropdownMenu, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { hasSelectedMultipleItemsSelector, itemIsHighlightedOnShiftSelectSelector } from '@air/redux-selected-items';
import { memo, MouseEvent, useCallback, useMemo, useState } from 'react';

import { ItemProps } from '~/components/Gallery/types';
import { BoardCellThumbnail } from '~/components/TableView/NameCell/BoardCellThumbnail';
import { NameCellContent } from '~/components/TableView/NameCell/NameCellContent';
import { PrivateBoardNameCellTitle } from '~/components/TableView/PrivateTableView/PrivateBoardNameCellTitle';
import { BOARD_NAME_CELL } from '~/constants/testIDs';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { canChangeBoardDetails } from '~/utils/permissions/boardPermissions';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface PrivateBoardNameCellProps extends ItemProps {
  isHovering: boolean;
  isSelected: boolean;
  board: Board;
}

export const PrivateBoardNameCell = memo(
  ({
    isHovering,
    isSelected,
    board,
    getItemMenuOptions,
    getSelectionMenuOptions,
    onItemClick,
    onItemCmdClick,
  }: PrivateBoardNameCellProps) => {
    const { boardPermissions } = useBoardPermissions({
      boardId: board.id,
    });
    const canEditName = canChangeBoardDetails(boardPermissions);

    const [isRenaming, setIsRenaming] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const isHighlightedFromShiftSelect = useAirSelector((st) => itemIsHighlightedOnShiftSelectSelector(st, board.id));
    const hasSelectedMultipleItems = useAirSelector(hasSelectedMultipleItemsSelector);

    const shouldShowMenu = isRenaming || (!isHighlightedFromShiftSelect && (isHovering || menuIsOpen));
    const shouldShowRenameButton =
      canEditName && !hasSelectedMultipleItems && !isHighlightedFromShiftSelect && isHovering && !isRenaming;

    const handleClickRenameButton = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsRenaming(true);
    }, []);

    const _title = useMemo(() => {
      return (
        <PrivateBoardNameCellTitle
          board={board}
          isRenaming={isRenaming}
          onClick={onItemClick}
          onCmdClick={onItemCmdClick}
          setIsRenaming={setIsRenaming}
        />
      );
    }, [board, isRenaming, onItemClick, onItemCmdClick]);

    const _image = useMemo(() => {
      return <BoardCellThumbnail thumbnail={board.thumbnails?.[0]} title={board.title} />;
    }, [board]);

    const dataTestId = BOARD_NAME_CELL;

    const ellipsisMenuOptions = useMemo(
      () => getItemMenuOptions({ trackLocation: 'table-item-ellipsis-menu' }),
      [getItemMenuOptions],
    );

    const selectionMenuOptions = getSelectionMenuOptions('table-multi-select-ellipsis-menu');
    const menuOptions = isSelected && selectionMenuOptions.length ? selectionMenuOptions : ellipsisMenuOptions;

    return (
      <NameCellContent data-testid={dataTestId} title={_title} image={_image}>
        <div className="flex items-center gap-1">
          {shouldShowRenameButton && (
            <IconButton
              appearance="ghost"
              color="grey"
              data-testid={`${dataTestId}-rename`}
              icon={Pencil}
              label="Rename"
              onClick={handleClickRenameButton}
              onMouseDown={handleClickRenameButton}
              onDoubleClick={(event) => event.stopPropagation()}
              size="medium"
            />
          )}
          {shouldShowMenu && !!menuOptions.length && (
            <DropdownMenu
              onOpenChange={setMenuIsOpen}
              trigger={
                <IconButton
                  appearance="ghost"
                  color="grey"
                  data-testid={`${dataTestId}-menu`}
                  icon={EllipsisVertical}
                  label="More options"
                  onClick={(event) => event.stopPropagation()}
                  onDoubleClick={(event) => event.stopPropagation()}
                  size="medium"
                />
              }
            >
              {renderDropdownItems({ options: menuOptions })}
            </DropdownMenu>
          )}
        </div>
      </NameCellContent>
    );
  },
);

PrivateBoardNameCell.displayName = 'PrivateBoardNameCell';
