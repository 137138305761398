import { Board } from '@air/api/types';
import { ReactNode } from 'react';

import {
  DNDMovableToBoardItem,
  DNDMovableToBoardItemProps,
} from '~/components/Shared/Drag/DNDMovableToBoardItem/DNDMovableToBoardItem';
import dragTypes from '~/components/Shared/Drag/dragTypes';

export interface DragDroppableBoardShellProps extends Pick<DNDMovableToBoardItemProps, 'onDragStart' | 'enabled'> {
  board: Board;
  children?: ReactNode;
  index?: number;
}

export const DraggableBoardCard = ({ board, children, index, ...rest }: DragDroppableBoardShellProps) => (
  <DNDMovableToBoardItem
    id={board.id}
    item={{
      type: dragTypes.boardCard,
      board,
      index,
    }}
    index={index}
    {...rest}
  >
    <>{children}</>
  </DNDMovableToBoardItem>
);
