import { RefObject, useCallback, useMemo } from 'react';

import { LibrarySearchToken } from '~/components/Search/SearchTokens/LibrarySearchToken';
import { useSearchTokenLogic, UseSearchTokenLogicParams } from '~/components/Search/SearchTokens/useSearchTokenLogic';
import { SearchBarHandle } from '~/components/SearchBar/SearchBar';
import { LibraryWithIcon } from '~/utils/librariesUtils';

export interface UseLibrarySearchTokenParams extends UseSearchTokenLogicParams {
  library: LibraryWithIcon | undefined;
  canRemove: boolean;
  searchBarRef: RefObject<SearchBarHandle>;
}

export const useLibrarySearchToken = ({
  library,
  hasValue,
  isFocused,
  canRemove,
  searchBarRef,
}: UseLibrarySearchTokenParams) => {
  const { isTokenVisible, showToken, wasTokenRemoved, hideToken } = useSearchTokenLogic({ hasValue, isFocused });

  const _onCloseClick = useCallback(() => {
    if (!isFocused) {
      searchBarRef?.current?.focus();
    }
    hideToken();
  }, [hideToken, isFocused, searchBarRef]);

  const LibraryToken = useMemo(
    () =>
      isTokenVisible && !!library ? (
        <LibrarySearchToken
          onCloseClick={canRemove ? _onCloseClick : undefined}
          title={library.title}
          Icon={library.Icon}
        />
      ) : null,
    [_onCloseClick, canRemove, isTokenVisible, library],
  );

  const onBackspacePress = useCallback(() => hideToken(), [hideToken]);

  return {
    LibraryToken,
    wasTokenRemoved,
    showToken,
    onBackspacePress,
  };
};
