import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldPlainText,
  CustomFieldPlainTextProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldPlainText';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateBoardCustomFieldPlainTextProps extends Omit<CustomFieldPlainTextProps, 'onSubmit'> {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  boardId: string;
}

export const PrivateBoardCustomFieldPlainText = memo(
  ({ field, trackingLocation, canEditCustomFields, boardId, ...rest }: PrivateBoardCustomFieldPlainTextProps) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;

    const onSubmit = useCallback(
      async (value: string) => {
        if (!boardId || !workspaceId) return;

        await updateItemsCustomFieldValue({
          boardIds: [boardId],
          baseCustomField: field,
          nextPlainTextValue: value,
          trackingLocation,
          workspaceId,
        });
      },
      [boardId, workspaceId, updateItemsCustomFieldValue, field, trackingLocation],
    );

    return <CustomFieldPlainText {...rest} onSubmit={canEditCustomFields ? onSubmit : undefined} field={field} />;
  },
);

PrivateBoardCustomFieldPlainText.displayName = 'PrivateBoardCustomFieldPlainText';
