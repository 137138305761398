import { TXProp } from '@air/zephyr';
import { EditableText, EditableTextProps } from '@air/zephyr-editable-text';
import { memo, useCallback, useMemo, useState } from 'react';

import { ContentHeaderTitle } from '~/components/PageHeader/ContentHeaderTitle';

export interface EditableContentHeaderTitleProps {
  onSubmit?: (newValue: string) => void;
  title: string | undefined;
  testId?: string;
  onClick?: () => void;
  label: string;
  editableTextTx?: TXProp;
  validationSchema?: EditableTextProps['validationSchema'];
}

export const EditableContentHeaderTitle = memo(
  ({ label, onSubmit, title, testId, onClick, editableTextTx, validationSchema }: EditableContentHeaderTitleProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const _onSubmit: EditableTextProps['onSubmit'] = useCallback(
      (values) => {
        const value = values['editable-text-value'].trim();

        if (value !== title) {
          onSubmit?.(value);
        }
      },
      [onSubmit, title],
    );

    const editableFieldTx: TXProp = useMemo(
      () => ({
        color: 'pigeon700',
        fontWeight: 'semibold',
        wordBreak: 'break-word',
        minWidth: isEditing ? 130 : 'unset',
        ...editableTextTx,

        EditableTextText: {
          lineHeight: 1.25,
        },

        EditableTextButton: {
          '&.focus-visible': {
            boxShadow: 'none !important',
          },
        },
      }),
      [editableTextTx, isEditing],
    );

    if (onSubmit) {
      return (
        <EditableText
          data-testid={testId}
          isSingleLine
          label={label}
          onSubmit={_onSubmit}
          maxLength={65}
          onEditingStateChange={setIsEditing}
          tx={editableFieldTx}
          value={title ? title.trim() : ''}
          variant="text-ui-24"
          required
          validationSchema={validationSchema}
        />
      );
    }

    return (
      <ContentHeaderTitle data-disableselect="true" data-testid={testId} onClick={onClick}>
        {title}
      </ContentHeaderTitle>
    );
  },
);

EditableContentHeaderTitle.displayName = 'EditableContentHeaderTitle';
