import { AirActionTrackingLocation } from '@air/analytics';
import { BoardCustomFieldValue } from '@air/api/types';
import { memo, useCallback } from 'react';

import {
  CustomFieldDateInput,
  CustomFieldDateInputProps,
} from '~/components/CustomFields/CustomFieldInput/CustomFieldDateInput';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useUpdateItemsCustomFieldValue } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

export interface PrivateBoardCustomFieldDateInputProps extends Omit<CustomFieldDateInputProps, 'onChange'> {
  field: BoardCustomFieldValue;
  trackingLocation: AirActionTrackingLocation;
  canEditCustomFields: boolean;
  boardId: string;
}

export const PrivateBoardCustomFieldDateInput = memo(
  ({ field, trackingLocation, canEditCustomFields, boardId, ...rest }: PrivateBoardCustomFieldDateInputProps) => {
    const { updateItemsCustomFieldValue } = useUpdateItemsCustomFieldValue();
    const { currentWorkspace } = useCurrentWorkspace();
    const workspaceId = currentWorkspace?.id;

    const onChange: CustomFieldDateInputProps['onChange'] = useCallback(
      async (date) => {
        if (!workspaceId) {
          return;
        }
        await updateItemsCustomFieldValue({
          boardIds: [boardId],
          baseCustomField: field,
          nextDateValue: date?.toISOString(),
          trackingLocation,
          workspaceId,
        });
      },
      [boardId, field, trackingLocation, updateItemsCustomFieldValue, workspaceId],
    );

    return (
      <CustomFieldDateInput {...rest} field={field} onChange={onChange} canEditCustomFields={canEditCustomFields} />
    );
  },
);

PrivateBoardCustomFieldDateInput.displayName = 'PrivateBoardCustomFieldDateInput';
